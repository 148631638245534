<template>
  <div class="resources-content">
    <div class="select-wrapper">
      <div class="select-btn" style="text-align: right">
        <el-button type="primary" @click="addClassify"
          ><i class="el-icon-plus"></i>添加分类</el-button
        >
      </div>
    </div>
    <el-table
      :data="resourcesTableData"
      class="customTable"
      style="width: 100%; margin-top: 20px; flex: 1"
      height="1%"
    >
      <el-table-column label="分类名称" min-width="150">
        <template slot-scope="scope">
          <el-tooltip
            placement="top-start"
            popper-class="subject-title-tttyyy"
            effect="light"
          >
            <div slot="content" v-html="scope.row.name"></div>
            <p
              class="text-overflow-2 subject-text"
              v-html="getTitle(scope.row.name)"
            ></p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-link
            type="success"
            :underline="false"
            @click="editSubject(scope.row)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            :underline="false"
            @click="deleteSubject(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加编辑分类dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      custom-class="green-dialog"
      :visible.sync="classifyDialogVisible"
      width="500px"
      @close="resetForm()"
    >
      <el-form
        ref="addClassifyForm"
        :model="addClassifyForm"
        label-width="80px"
        :rules="addClassifyRules"
        label-position="top"
      >
        <el-form-item label="输入分类名称" prop="name">
          <el-input
            v-model="addClassifyForm.name"
            ref="cursorInput"
            placeholder="请填写分类名称"
            clearable
            maxlength='20'
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="classifyDialogVisible = false">取 消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="addToForm('addClassifyForm')"
          >确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination
      class="pager-center"
      style="margin: 20px 0 30px; text-align: center"
      :current-page="classifyPages.page"
      :page-size="classifyPages.pageSize"
      :total="classifyPages.total"
      layout="prev, pager, next, jumper"
      @current-change="classifyChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  studentClassificationData,
  addClassifyData,
  delClassify,
} from "@/utils/apis.js";
export default {
  name: "Classify",
  data() {
    return {
      //表格列表
      resourcesTableData: [],
      classifyDialogVisible: false,
      dialogTitle: "",
      //编辑字段
      testee_category_id: "",
      //弹窗部分
      addClassifyForm: {
        name: "",
      },

      //验证规则
      addClassifyRules: {
        name: [{ min: 2, max: 20,required: true, message: "请输入分类名称", trigger: "blur" }],
      },

      //列表分页
      classifyPages: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getAssignList();
  },
  methods: {
    //打开添加分类弹窗
    addClassify() {
      this.dialogTitle = "添加分类";
      this.classifyDialogVisible = true;
    },
    //添加分类确定请求
    addToForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            name: this.addClassifyForm.name,
            testee_category_id: this.testee_category_id,
          };
          //编辑
          if (this.testee_category_id) {
            params.testee_category_id = this.testee_category_id;
          }
          addClassifyData(params)
            .then((res) => {
              this.$message.success(res.msg);
              this.classifyDialogVisible = false;
              this.getAssignList();
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    },
    //编辑
    editSubject(row) {
      console.log(row);
      this.classifyDialogVisible = true;
      this.dialogTitle = "编辑分类";
      this.addClassifyForm.name = row.name;
      this.testee_category_id = row.testee_category_id;
    },
    //删除
    deleteSubject(row) {
      this.$confirm(`是否删除该分类，删除将无法恢复`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      }).then(() => {
          let params = {
            testee_category_id: row.testee_category_id,
          };
        delClassify(params).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
            duration: 1000,
            onClose:() => {
                //分页只有一条数据删除之后为空的处理
              if((this.classifyPages.total-1)%this.classifyPages.pageSize===0){
                  this.classifyPages.page = this.classifyPages.page-1
              }
              this.getAssignList();
            }
          })
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },

    //清除新建表单
    resetForm() {
      this.$refs.addClassifyForm.resetFields();
      this.addClassifyForm.name = "";
      this.testee_category_id = ""
    },
    //过虑
    getTitle(row) {
      row = row.replace(/<img(.*?)>/g, "[图片]");
      return row;
    },
    //分页改变事件
    classifyChange(val) {
      this.classifyPages.page = val;
      this.getAssignList();
    },

    //分类列表请求
    getAssignList() {
      let params = {
        paging: 1,
        page: this.classifyPages.page,
        pageSize: this.classifyPages.pageSize,
      };
     
      studentClassificationData(params)
        .then((res) => {
          //  console.log(res)
          this.resourcesTableData = res.data.list;
          this.classifyPages.total = res.data.total;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
}
.select-btn {
  i {
    font-size: 12px;
    margin-right: 5px;
  }
}
</style>